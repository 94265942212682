<template>
<div class="main home">
    <div class="home-box" style="padding-bottom: 20px;">
        <h1>用户信息</h1>
        <div class="home-content">
            <!-- 查询条件 -->
            <search-item :searchInfo.sync="info" @searchList="searchList" @goRechargeHistory="goRechargeHistory"></search-item>
            <!-- 表格 -->
            <div class="table_container">
                <el-table size="mini" border :data="tableData" stripe v-loading="tableLoading" style="width: 100%" align='center'>
                    <el-table-column prop="userNo" label="用户编号" align='center'>
                    </el-table-column>
                    <el-table-column prop="userName" label="用户名称" align='center'>
                    </el-table-column>
                    <el-table-column prop="userTel" label="用户电话" align='center'>
                    </el-table-column>
                    <el-table-column prop="amount" label="账户余额" align='center'>
                    </el-table-column>
                    <el-table-column prop="userAddr" label="用户地址" align='center'>
                    </el-table-column>
                    <el-table-column prop="deviceNo" label="水表编号" align='center'>
                    </el-table-column>
                    <!-- <el-table-column prop="registerTime" label="水表口径" align='center'>
                    </el-table-column> -->
                    <el-table-column prop="readValue" label="用水量" align='center'>
                    </el-table-column>
                    <el-table-column prop="readTime" label="采集日期" align='center'>
                    </el-table-column>
                    <el-table-column prop="installDate" label="安装时间" align='center'>
                    </el-table-column>
                </el-table>
                <br>
            </div>
        </div>
        <h1>充值</h1>
        <div class="home-box" style="padding:20px;">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="充值方式">
                    <el-radio-group v-model="payType">
                        <el-radio-button label="现金"></el-radio-button>
                        <el-radio-button disabled label="微信"></el-radio-button>
                        <el-radio-button disabled label="支付宝"></el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="充值金额" required>
                    <el-input v-model="rechargeAmount" type="number" placeholder="请输入充值金额"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input type="textarea" placeholder="请填写备注" style="width:400px;"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="mini" icon="el-icon-wallet" :loading="commandLoading" plain @click="rechargeMoney()">充值</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
    <el-dialog :modal-append-to-body='false' title="新建/修改" :visible.sync="modifyEnprVisible" width="47%">
        <el-form ref="form" label-width="120px" :inline="true">
            <el-form-item label="模板分类" required>
                <el-select style="width:240px;" v-model="newEnpr" filterable clearable placeholder="请选择项目分类">
                    <el-option v-for="item in arrEnpr" :key="item.id" :label="item.enprName + ' - ' + item.enprNo" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="模板名称" required>
                <el-input style="width:240px;" clearable></el-input>
            </el-form-item>
            <el-form-item label="计价模式" required>
                <el-select style="width:240px;" v-model="newEnpr" filterable clearable placeholder="请选择计价模式">
                    <el-option v-for="item in arrJJMS" :key="item.id" :label="item.jjmsName" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="付费模式" required>
                <el-select style="width:240px;" v-model="newEnpr" filterable clearable placeholder="请选择付费模式">
                    <el-option v-for="item in arrJJMS" :key="item.id" :label="item.jjmsName" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="计价周期" required>
                <el-select style="width:240px;" v-model="newEnpr" filterable clearable placeholder="请选择计价周期">
                    <el-option v-for="item in arrJJMS" :key="item.id" :label="item.jjmsName" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="周期数值" required>
                <el-input type="number" style="width:240px;" clearable></el-input>
            </el-form-item>
            <el-form-item label="状态" required>
                <el-select style="width:240px;" v-model="newEnpr" filterable clearable placeholder="请选择收费类型">
                    <el-option v-for="item in arrEnpr" :key="item.id" :label="item.enprName + ' - ' + item.enprNo" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注">
                <el-input style="width:240px;" clearable></el-input>
            </el-form-item>
            <el-form-item label="包月价格(元)" required>
                <el-input style="width:240px;" clearable></el-input>
            </el-form-item>
            <el-form-item style="padding-left: 100px;">
                <el-button type="success" size="mini" plain :loading="enprLoading" @click="updateEnprSumit">提交</el-button>
                <el-button size="mini" @click="closeEnprDialog">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
    <el-dialog :modal-append-to-body='false' title="发送指令" :visible.sync="commandVisible" width="25%">
        <el-form ref="form" label-width="120px">
            <el-form-item label="指令选择">
                <el-radio v-model="command" label="0">开阀</el-radio>
                <el-radio v-model="command" label="1">关阀</el-radio>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" :loading="commandLoading" @click="onSubmit">提交</el-button>
                <el-button @click="closeCommandDialog">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</div>
</template>

<script>
import Pagination from "@/components/pagination";
import SearchItem from "./searchItem";
export default {
    data() {
        return {
            // 表格loading
            tableLoading: false,
            tableData: [], //表格数据
            paginationInfo: {
                pageIndex: 1, // 当前位于哪页
                pageTotal: 8, //表格总页数
                pageSize: 10, //每页显示条数
                pageSizes: [5, 10, 15, 20], //每页显示个数选择器的选项设置
                layout: "total, sizes, prev, pager, next, jumper" // 翻页属性
            },
            pidList: [],
            yxqVisible: false,
            registerId: "",
            info: {
                userNo: "",
                userName: ""
            },
            commandVisible: false,
            command: '0',
            modifyEnprVisible: false,
            nowEnpr: '',
            nowDeviceId: '',
            newEnpr: '',
            arrEnpr: [],
            selectedRow: '',
            enprLoading: false,
            commandLoading: false,
            payType: '现金',
            deviceNo: '',
            rechargeAmount: 0,
            arrJJMS: [{
                    id: 1,
                    jjmsName: '统一定价(包月)'
                },
                {
                    id: 2,
                    jjmsName: '定量(固定单价)'
                },
                {
                    id: 3,
                    jjmsName: '阶梯'
                }
            ]
        }
    },
    mounted() {
        let that = this
        this.getUserInfo(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
    },
    methods: {
        //  获取表格信息
        getUserInfo(currpage, pageSize, info) {
            console.log(info)
            this.tableLoading = true;
            let params = {
                userNo: info.userNo,
                userName: info.userName
            }
            this.tableData = []
            this.$api.getUserDeviceInfo(params).then(res => {
                console.log('000', res.data.list)
                this.tableLoading = false;
                if (res && res.code == 0) {
                    this.paginationInfo.pageTotal = parseInt(res.data.total)
                    this.paginationInfo.pageIndex = res.data.pageNum
                    this.tableData.push(res.data.list[0])
                    this.deviceNo = res.data.list[0].deviceNo
                    console.log(this.tableData)
                }
            }).catch(error => {
                console.log(error)
                this.tableLoading = false;
            })
        },
        rechargeMoney(){
            let params = {
                deviceNo: this.deviceNo,
                rechargeType: 1,
                rechargeAmount: this.rechargeAmount,
                remark:this.remark
            }
                this.commandLoading = true
                this.$api.recharge(params).then(res => {
                    if (res && res.code === 200) {
                        this.commandLoading = false
                        this.$message({
                            message: '充值成功',
                            type: 'success'
                        })
                        this.getUserInfo(this.paginationInfo.pageIndex, this.paginationInfo.pageSize, this.info)
                    } else {
                        this.commandLoading = false
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                })
        },
        searchList(e) {
            console.log(e)
            this.info = e
            this.getUserInfo(1, this.paginationInfo.pageSize, e)
        },
        // 上下分页
        handleCurrentChange(val) {
            // console.log('上下分页',val)
            let that = this
            this.paginationInfo.pageIndex = val
            this.getUserInfo(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
        },
        // 每页显示多少条
        handleSizeChange(val) {
            // console.log('每页显示多少条',val)
            let that = this
            this.paginationInfo.pageSize = val
            this.getUserInfo(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
        },
        addTemplate() {
            this.modifyEnprVisible = true
        },
        goRechargeHistory(e) {
            console.log(e)
            this.$router.push({
                path: '/sfgl/czjl'
            })
        },
        submitDialog(e) {
            this.registerId = e.registerId
            this.centerDialogVisible = true
        },
        onSubmit() {
            let params = {
                imei: this.selectedRow.imei,
                command: this.command
            }
            this.commandLoading = true
            this.$api.releaseOrder(params).then(res => {
                if (res && res.code === 0) {
                    this.commandVisible = false
                    this.commandLoading = false
                    this.$message({
                        message: '阀控指令发送成功,等待下发到设备',
                        type: 'success'
                    })
                } else {
                    this.commandLoading = false
                    this.$message({
                        message: '指令下发失败',
                        type: 'error'
                    })
                }
            })
        },
        closeCommandDialog() {
            this.commandVisible = false
        },
        closeEnprDialog() {
            this.modifyEnprVisible = false
            this.newEnpr = ''
        },
        //更新设备所在的水司
        updateEnprSumit() {
            if (this.newEnpr == '') {
                this.$message.warning('请选择新的水司')
                return
            }
            this.enprLoading = true
            this.$api.updateEnpr({
                id: this.nowDeviceId,
                enterpriseId: this.newEnpr
            }).then(res => {
                if (res.code === 0) {
                    this.$message.success('修改水司成功')
                    this.getUserInfo(this.paginationInfo.pageIndex, this.paginationInfo.pageSize, this.info)
                    this.modifyEnprVisible = false
                    this.enprLoading = false
                    this.newEnpr = ''
                } else {
                    this.enprLoading = false
                    this.$message.error('修改水司失败')
                }
            })
        },
        //启用或停用设备
        stateControl(e) {
            let state = e === 0 ? '停用' : '启用'
            this.$confirm(`确定要${state}该设备吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

            })
        },
        del(e) {
            console.log(e)
            let that = this
            this.$confirm('确定要删除该设备吗?删除后将无法恢复，需重新注册', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.delDevice({
                    id: e.id
                }).then(res => {
                    if (res.code === 0) {
                        this.getUserInfo(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: '删除失败'
                        });
                    }
                })
            }).catch(() => {});
        },
        //获取所有水司
        getAllEnpr() {
            this.$api.getAllEnpr({}).then(res => {
                if (res.code === 0) {
                    this.arrEnpr = res.data
                }
            })
        },
        //发送指令弹框
        sendCommand(e) {
            this.commandVisible = true
            this.selectedRow = e
        },
        //修改水司弹框
        modifyEnpr(e) {
            console.log(e)
            this.nowEnpr = e.enterpriseName
            this.nowDeviceId = e.id
            this.modifyEnprVisible = true
        }
    },
    components: {
        Pagination,
        SearchItem
    }
}
</script>

<style>
/* 标题 */
.menu .title1 img {
    top: 22px;
}

.menu .title1 h3 {
    font-size: 20px;
    margin-top: -14px;
}

/* 分页 */
.el-pagination button,
.el-pagination span:not([class*=suffix]) {
    padding: 0 10px;
}

.home .block-title {
    width: 100%;
    padding: 0 0 28px 20px;
    display: flex;
    align-items: center;
}

.home .block-title h3 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
}

.home .a-text {
    text-decoration: underline;
    color: #1e61ce;
}

.home .block-title .btn {
    width: 180px;
    height: 35px;
    background-color: #1e61ce;
    border-radius: 20px;
    margin-left: 20px;
    font-weight: normal;
    font-stretch: normal;
    color: #ffffff;
    padding: 0;
}

.home .btn span {
    font-size: 16px !important;
    color: #fff !important;
}

.home .block-title span {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #63717A;
}

.home .top {
    width: 100%;
    padding: 0 0 10px 0;
    border-radius: 10px;
    background: #fefefe;
}

.home .top .title {
    width: 100%;
    height: 90px;
    line-height: 90px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    font-family: PingFangSC-Semibold;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
    border-bottom: 1px solid #f2f3f5;
}

.home .el-col {
    border-radius: 10px;
}

.home .grid-content {
    border-radius: 10px;
    /* min-height: 36px; */
}

.home .leftInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.home .leftInfo .headPic {
    width: 165px;
    height: 165px;
    border-radius: 100%;
    border: 8px solid #fff;
    position: relative;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-shadow: 0px 12px 30px 0px rgba(6, 37, 117, 0.12);
}

.home .leftInfo .headPic img {
    width: 100%;

}

.home .rightInfo {
    margin-top: 10px;
    padding-right: 10px;
}

.home .rightInfo .name-text {

    margin-bottom: 20px;
    font-family: PingFangSC-Semibold;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
}

.home .rightInfo .info-text img {
    width: 26px;
    height: 26px;
    margin-right: 20px;
}

.home .rightInfo .info-text {
    margin-bottom: 20px;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    letter-spacing: 0px;
    color: #63717a;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    text-align: left
}

.home .rightInfo .info-text h1 {
    font-size: 20px;
    font-weight: 600;
    font-family: PingFangSC-Medium;
    color: #2d3039;
    margin-left: 10px;
    display: block;
    font-size: 90.5%;
}

.home .middle {
    width: 100%;
    border-radius: 10px;
}

.home .middle .grid-content {
    background: #fff
}

.home .title1 {
    width: 100%;
    display: flex;
    padding: 0 20px 20px 20px;
    align-items: center;
    margin-top: -10px;
    border-bottom: 1px solid #f2f3f5;
}

.home .title1 h3 {
    font-size: 20px;
    margin-top: -10px;
}

.home .title1 img {
    position: relative;
    top: 18px;
    margin-right: 10px;
}

.home .ajs-Title {
    margin-left: 20px;
}

.home .ajs {
    margin-left: 20px;
    color: #0033cf;
}

.home .left-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    /* border-right: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8; */
}

.home .right-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

.home .main-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

/*案件办理天数 标签统计 hmm 090403*/
.home .labelModule {
    background: #F2F5FA;
    border: 1px solid #D6E0F0;
    border-radius: 10px;
    display: inline-block;
    width: 100%;
    padding: 20px;
}

.home .labelModule:first-child {
    margin-bottom: 20px;
}

.home .labelModule ul li {
    width: 100%;
    font-size: 16px;
    color: #63717A;
    text-align: center
}

.home .labelModule ul li:first-child {
    margin-bottom: 10px;
}

.home .labelModule ul li font {
    font-size: 41px;
    color: #2D3039;
}

/*公共部分 hmm*/
.p20 {
    padding: 20px;
}

.pb30 {
    padding-bottom: 30px;
}

/*办案类型 数据超出 显示样式 hmm 090404*/

.widthBig .labelModule {
    width: 49.2%;
}

.widthBig .labelModule:first-child {
    margin-bottom: 30px;
    margin-right: 20px;
}

.widthSmall {
    float: right;
}

.openstate {
    color: #8CC663;
}

.stopstate {
    color: #e92d0c;
}
</style>
